import React, { useState, useEffect } from 'react';
import './App.css';

function App() {
  const [text, setText] = useState(() => localStorage.getItem('text') || '');
  const [timer, setTimer] = useState(() => parseInt(localStorage.getItem('timer')) || 0);
  const [isRunning, setIsRunning] = useState(() => JSON.parse(localStorage.getItem('isRunning')) || false);
  const [targetWordCount, setTargetWordCount] = useState(() => localStorage.getItem('targetWordCount') || '');
  const [maxTime, setMaxTime] = useState(() => localStorage.getItem('maxTime') || '');
  const [lockedWordCount, setLockedWordCount] = useState(() => parseInt(localStorage.getItem('lockedWordCount')) || null);
  const [lockedMaxTime, setLockedMaxTime] = useState(() => parseInt(localStorage.getItem('lockedMaxTime')) || null);
  const [showTryAgain, setShowTryAgain] = useState(() => JSON.parse(localStorage.getItem('showTryAgain')) || false);
  const [isHardcoreMode, setIsHardcoreMode] = useState(() => JSON.parse(localStorage.getItem('isHardcoreMode')) || true);
  const [showResults, setShowResults] = useState(false);

  useEffect(() => {
    let interval;
    if (isRunning) {
      interval = setInterval(() => {
        setTimer((prevTimer) => {
          const newTimer = prevTimer + 1;
          localStorage.setItem('timer', newTimer.toString());
          if (lockedMaxTime !== null && newTimer >= lockedMaxTime) {
            clearInterval(interval);
            setIsRunning(false);
            setShowTryAgain(true);
            setShowResults(true);
            localStorage.setItem('isRunning', 'false');
            localStorage.setItem('showTryAgain', 'true');
          }
          return newTimer;
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isRunning, lockedMaxTime]);

  useEffect(() => {
    localStorage.setItem('text', text);
    localStorage.setItem('isRunning', JSON.stringify(isRunning));
    localStorage.setItem('targetWordCount', targetWordCount);
    localStorage.setItem('maxTime', maxTime);
    localStorage.setItem('lockedWordCount', lockedWordCount !== null ? lockedWordCount.toString() : '');
    localStorage.setItem('lockedMaxTime', lockedMaxTime !== null ? lockedMaxTime.toString() : '');
    localStorage.setItem('showTryAgain', JSON.stringify(showTryAgain));
    localStorage.setItem('isHardcoreMode', JSON.stringify(isHardcoreMode));
  }, [text, isRunning, targetWordCount, maxTime, lockedWordCount, lockedMaxTime, showTryAgain, isHardcoreMode]);

  const handleTextChange = (e) => {
    const newValue = e.target.value;
    if (newValue.length >= text.length) {
      setText(newValue);
      if (!isRunning && newValue.trim() !== '') {
        setIsRunning(true);
      }
    }
  };

  const handleTargetWordCountChange = (e) => {
    setTargetWordCount(e.target.value);
  };

  const handleMaxTimeChange = (e) => {
    setMaxTime(e.target.value);
  };

  const isInputValid = () => {
    const wordCount = parseInt(targetWordCount);
    const timeInSeconds = parseTimeToSeconds(maxTime);
    return !isNaN(wordCount) && wordCount > 0 && timeInSeconds > 0;
  };

  const handleEnterClick = () => {
    if (isInputValid()) {
      setLockedWordCount(parseInt(targetWordCount));
      setLockedMaxTime(parseTimeToSeconds(maxTime));
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && isInputValid()) {
      handleEnterClick();
    }
  };

  const resetEverything = () => {
    setTimer(0);
    setIsRunning(false);
    setText('');
    setShowTryAgain(false);
    setLockedWordCount(null);
    setLockedMaxTime(null);
    setTargetWordCount('');
    setMaxTime('');
    setShowResults(false);
    localStorage.removeItem('text');
    localStorage.removeItem('timer');
    localStorage.removeItem('isRunning');
    localStorage.removeItem('targetWordCount');
    localStorage.removeItem('maxTime');
    localStorage.removeItem('lockedWordCount');
    localStorage.removeItem('lockedMaxTime');
    localStorage.removeItem('showTryAgain');
  };

  const wordCount = text.trim().split(/\s+/).filter(word => word !== '').length;

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours > 0 ? hours + ':' : ''}${minutes < 10 ? '0' : ''}${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  const parseTimeToSeconds = (timeString) => {
    const parts = timeString.split(':').map(part => parseInt(part, 10));
    if (parts.length === 3) {
      return parts[0] * 3600 + parts[1] * 60 + parts[2];
    } else if (parts.length === 2) {
      return parts[0] * 60 + parts[1];
    } else if (parts.length === 1) {
      return parts[0] * 60;
    }
    return 0;
  };

  const toggleHardcoreMode = () => {
    setIsHardcoreMode(!isHardcoreMode);
  };

  const isTextareaVisible = lockedWordCount !== null && lockedMaxTime !== null && !showTryAgain;

  return (
    <div className="App" style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
      <header className="App-header" style={{ flex: '1 0 auto', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', padding: '20px 20px 0', overflow: 'auto' }}>
        {!isTextareaVisible && (
          <div style={{ marginBottom: '40px' }}>
            <h1 style={{ fontSize: 'calc(24px + 2vmin)', marginBottom: '10px' }}>Deadline Draft</h1>
            {!showResults && (
              <>
                <p style={{ fontSize: 'calc(10px + 1vmin)', textAlign: 'center', margin: '5px 0' }}>
                  Hit your target within the time limit or everything is deleted.
                </p>
                <p style={{ fontSize: 'calc(10px + 1vmin)', textAlign: 'center', margin: '5px 0' }}>
                  No backspace. No copy/paste. Just you against the clock.
                </p>
              </>
            )}
          </div>
        )}
        <div style={{ width: '100%', maxWidth: '600px', margin: '0 auto' }}>
          {lockedWordCount === null && lockedMaxTime === null ? (
            <div style={{ marginBottom: '10px', padding: '50px 0' }}>
              <input
                type="number"
                value={targetWordCount}
                onChange={handleTargetWordCountChange}
                onKeyPress={handleKeyPress}
                placeholder="Target Word Count"
                style={{ marginRight: '10px', width: 'calc(100% - 20px)', maxWidth: '200px', height: '30px', fontSize: '16px', marginBottom: '10px' }}
              />
              <input
                type="text"
                value={maxTime}
                onChange={handleMaxTimeChange}
                onKeyPress={handleKeyPress}
                placeholder="Time Limit (HH:MM:SS)"
                style={{ marginRight: '10px', width: 'calc(100% - 20px)', maxWidth: '200px', height: '30px', fontSize: '16px', marginBottom: '10px' }}
              />
              <button 
                onClick={handleEnterClick} 
                disabled={!isInputValid()}
                style={{ 
                  width: '100%', 
                  maxWidth: '200px', 
                  height: '30px', 
                  fontSize: '16px', 
                  marginBottom: '10px',
                  opacity: isInputValid() ? 1 : 0.5,
                  cursor: isInputValid() ? 'pointer' : 'not-allowed'
                }}
              >
                Enter
              </button>
              <div style={{ marginTop: '10px' }}>
                <label>
                  <input
                    type="checkbox"
                    checked={isHardcoreMode}
                    onChange={toggleHardcoreMode}
                  />
                  Hardcore Mode
                </label>
              </div>
              {isHardcoreMode ? (
                <div style={{ color: 'red', marginTop: '5px', fontSize: '14px' }}>
                  Warning: anything written in hardcore mode will be deleted if you do not hit your Target Word Count within the time limit.
                </div>
              ) : (
                <div style={{ color: 'green', marginTop: '5px', fontSize: '14px' }}>
                  Stress Free Mode Enabled: you won't lose your work if you don't hit your target word count within the time limit.
                </div>
              )}
            </div>
          ) : (
            <>
              {showResults && (
                <>
                  {wordCount < lockedWordCount || timer > lockedMaxTime ? (
                    <div style={{ color: 'red', fontSize: 'calc(24px + 2vmin)', fontWeight: 'bold', marginBottom: '10px', textAlign: 'center' }}>
                      Ouch. I'm so sorry...
                    </div>
                  ) : (
                    <>
                      <div style={{ color: 'orange', fontSize: 'calc(24px + 2vmin)', fontWeight: 'bold', marginBottom: '10px', textAlign: 'center' }}>
                        SUCCESS
                      </div>
                      <div style={{ color: 'orange', fontSize: 'calc(10px + 1vmin)', marginBottom: '20px', maxWidth: '100%', textAlign: 'center', margin: '0 auto' }}>
                        Copy and save your work somewhere safe before clicking try again! Seriously, do it. You hit that button and it's gone forever.
                      </div>
                    </>
                  )}
                  <div style={{ marginBottom: '20px', marginTop: '20px', color: 'white', textAlign: 'center' }}>
                    Target: {lockedWordCount} words in {formatTime(lockedMaxTime)}
                  </div>
                  <div style={{ color: 'white', display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
                    <span style={{ marginBottom: '10px' }}>Your Results:</span>
                    <span>Words written: {wordCount}</span>
                    <span>Time: {formatTime(timer)}</span>
                  </div>
                </>
              )}
              {!showTryAgain ? (
                <>
                  <div style={{ marginBottom: '10px', color: 'white', textAlign: 'center', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <div style={{ marginRight: '30px' }}>Target: {lockedWordCount} words</div>
                    <div>Time: {formatTime(lockedMaxTime)}</div>
                  </div>
                  <textarea
                    rows="20"
                    value={text}
                    onChange={handleTextChange}
                    placeholder="Begin typing to start timer..."
                    onCopy={(e) => e.preventDefault()}
                    onCut={(e) => e.preventDefault()}
                    onPaste={(e) => e.preventDefault()}
                    spellCheck="false"
                    autoCorrect="off"
                    autoCapitalize="off"
                    data-ms-editor="false"
                    style={{
                      WebkitSpellCheck: 'false',
                      MozSpellCheck: 'false',
                      msSpellCheck: 'false',
                      width: '100%',
                      maxWidth: '600px',
                      height: '300px',
                      resize: 'vertical'
                    }}
                  />
                  <div style={{ fontSize: '12px', color: 'white', marginTop: '5px', display: 'flex', justifyContent: 'space-between', width: '100%', maxWidth: '600px' }}>
                    <div>Time: {formatTime(timer)}</div>
                    <div>Words: {wordCount}</div>
                  </div>
                </>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                  <button onClick={resetEverything} style={{ fontSize: '24px', padding: '10px 20px', marginBottom: '20px', width: '100%', maxWidth: '200px' }}>Try Again</button>
                  {(!isHardcoreMode || (isHardcoreMode && wordCount >= lockedWordCount)) && (
                    <div style={{ marginTop: '10px', maxWidth: '100%', whiteSpace: 'pre-wrap', textAlign: 'left', overflowWrap: 'break-word' }}>
                      <p>{text}</p>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
      </header>
      {!isTextareaVisible && (
        <footer style={{ 
          paddingBottom: '10px', 
          backgroundColor: '#282c34', 
          color: 'white', 
          fontSize: 'calc(8px + 1vmin)', 
          textAlign: 'center',
          position: 'sticky',
          bottom: 0,
          width: '100%'
        }}>
          <div style={{ paddingBottom: '10px' }}>Open Beta - your <a href="https://docs.google.com/forms/d/e/1FAIpQLSesrJ-MfuTOHSsx4nE8UYh_R41IMSF6K2gTuPkZtjSDN4hFVg/viewform?usp=sf_link" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>feedback</a> is appreciated!</div>
          <div>Developed by Creag Munroe & <a href="https://www.elegantliterature.com/" target="_blank" rel="noopener noreferrer" style={{ color: 'white', textDecoration: 'underline' }}>Elegant Literature</a></div>
        </footer>
      )}
    </div>
  );
}

export default App;